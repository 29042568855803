import InstagramUnfollowers from "./InstagramUnfollowers";

function App() {
  return (
    <>
      <InstagramUnfollowers />
    </>
  );
}

export default App;
