import React, { useState, useRef } from 'react';
import JSZip from 'jszip';

const InstagramUnfollowers = () => {
  const [activeTab, setActiveTab] = useState('upload');
  const [unfollowers, setUnfollowers] = useState([]);
  const [error, setError] = useState('');
  const [manualFollowers, setManualFollowers] = useState('');
  const [manualFollowing, setManualFollowing] = useState('');
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file || file.type !== 'application/zip') {
      setError('Please upload a valid ZIP file.');
      return;
    }
    await processFile(file);
  };

  const processFile = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const zip = await JSZip.loadAsync(arrayBuffer);
      processInstagramData(zip);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    if (!file || file.type !== 'application/zip') {
      setError('Please upload a valid ZIP file.');
      return;
    }
    processFile(file);
  };

  const processInstagramData = async (zip) => {
    setError('');
    setUnfollowers([]);

    const followersRaw = await loadJsonFromZip(zip, 'connections/followers_and_following/followers_1.json');
    const followingRaw = await loadJsonFromZip(zip, 'connections/followers_and_following/following.json');

    if (!followersRaw || !followingRaw) {
      setError('Unable to find necessary data in the ZIP file.');
      return;
    }

    const followers = parseInstagramData(followersRaw);
    const following = parseInstagramData(followingRaw.relationships_following);

    const followersSet = new Set(followers);
    const unfollowers = following.filter(f => !followersSet.has(f));

    setUnfollowers(unfollowers);
  };

  const loadJsonFromZip = async (zip, filePath) => {
    try {
      const file = await zip.file(filePath).async('string');
      return JSON.parse(file);
    } catch (error) {
      console.error(`Error loading ${filePath}:`, error);
      return null;
    }
  };

  const parseInstagramData = (data) => {
    return data.map(entry => entry.string_list_data[0].value);
  };

  const handleManualSubmit = () => {
    const followers = manualFollowers.split('\n').map(f => f.trim()).filter(f => f);
    const following = manualFollowing.split('\n').map(f => f.trim()).filter(f => f);

    const followersSet = new Set(followers);
    const unfollowers = following.filter(f => !followersSet.has(f));

    setUnfollowers(unfollowers);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="react-app">
      <div>
        <button
          className={`tab ${activeTab === 'upload' ? 'active' : ''}`}
          onClick={() => setActiveTab('upload')}
        >
          Upload ZIP
        </button>
        <button
          className={`tab ${activeTab === 'manual' ? 'active' : ''}`}
          onClick={() => setActiveTab('manual')}
        >
          Compare Lists
        </button>
      </div>
      {activeTab === 'upload' && (
        <div
          className={`drop-zone ${dragging ? 'dragging' : ''}`}
          onDragOver={(e) => { e.preventDefault(); setDragging(true); }}
          onDragLeave={() => setDragging(false)}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: 'none' }}
          />
          <p>Drag & drop a ZIP file here, or click to select a file</p>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
      )}
      {activeTab === 'manual' && (
        <div>
          <textarea
            placeholder="Enter followers list (one username per line)"
            value={manualFollowers}
            onChange={(e) => setManualFollowers(e.target.value)}
            rows="10"
            cols="30"
          />
          <textarea
            placeholder="Enter following list (one username per line)"
            value={manualFollowing}
            onChange={(e) => setManualFollowing(e.target.value)}
            rows="10"
            cols="30"
          />
          <button className="compare-button" onClick={handleManualSubmit}>Submit</button>
        </div>
      )}
      {unfollowers.length > 0 && (
        <table border="1">
          <thead>
            <tr>
              <th>Unfollowers ({unfollowers.length})</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {unfollowers.map((f, index) => (
              <tr key={index}>
                <td>{f}</td>
                <td>
                  <a href={`https://www.instagram.com/${f}`} target="_blank" rel="noopener noreferrer">
                    View Profile
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InstagramUnfollowers;
